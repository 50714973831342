import React from "react"
import "./expertise.css"
const Blog =()=>{
    const Blogdata1 = [
        {
          id: 1,

          title: "PACK STARTUP",
          cover: "./images/rocket.png",

          category: " ✔Version mono magasin  1 utilisateur  <br/> ✔ Gestion de stock mono magasin<br/> ✔ Gestion des clients<br/> ✔ Gestion des fournisseurs <br/> ✔ Gestion de la tresorerie",
          
        },
        {
          id: 2,
 
          title: "PACK GROWTH",
          cover: "./images/growth_9524126.png",

          category: "✔Version multi-depôts 3 utilisateurs  <br/> ✔ Gestion de stock multi-depôts <br/>avec les bons de transfert<br/>  ✔ Gestion des clients<br/> ✔ Gestion des fournisseurs <br/> ✔ Gestion de la tresorerie",
        },
        {
          id: 3,

          title: "PACK PRO",
          cover: "./images/pro.png",

          category:     " ✔ Version synchronisée web/desktop<br/>  ✔ Gestion des clients / Fournisseurs  <br/> ✔ Gestion Mono-Stock / Multi-Stocks <br/> ✔ Gestion des inventaires <br/> ✔ Gestion de la caisse <br/> ✔ Modules paramétrables selon secteur<br/> d'activité <br/> ",
        },
        {
          id: 4,

          title: "Module",
          cover: "./images/liv4.png",

          category:     " ✔ Module de gestion des représentants commerciaux",
        },
        {
          id: 5,

          title: " Version desktop  paramétrable",
          
          cover: "./images/ok.png",

          category:     " ✔ Gestion mono et multi-stocks<br/> ✔ Gestion des ventes <br/>  ✔Gestion des achats<br/> ✔Gestion de la banque <br/> ✔ Gestion de la caisse<br/> ✔ Parametrage par module selon besoin <br/> ",
        },
        
        {
          id: 6,

          title: " Création de site web",
          
          cover: "./images/st.png",

          category:     "",
        },
   
      ]

    return(
        <>
        <section className="blog services">
            <div className="container ">
                <div className="heading" data-aos='zoom-in-down'>
                    {/* <h3>Pourquoi nous choisir ?</h3> */}
                    <h1 style={{marginLeft:"-95px"}}> NOS PACKS </h1>
                    </div>
                    <div className="contain grid topMargin">
                        {Blogdata1.map((val)=>{
                            return(
                                <div className="container2"  key={val.id}  data-aos='flip-left'  > 
                                 <img src ={val.cover} alt="" className="image2"/>
                                        <div className="text marginmoin">
                                            <span>{val.date}</span>

                                          <h2>{val.title}</h2>        
                                          <div dangerouslySetInnerHTML={ {__html: val.category} } />    
                                              </div>
                                        </div>
                            )})}
                                </div>
               </div> </section></>
    )
}
export default Blog;